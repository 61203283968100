<template>
  <UILoading v-if="loading" />
  <div v-else-if="statistieken" class="flex flex-col gap-8">
    <div class="card">
      <h2 class="flex flex-row justify-between">
        <span>Bedrijf - Betaaltermijnen</span>
        <UIButtonCompact v-model="compact" class="small" />
      </h2>

      <div>
        <BamTarget title="Betaaltermijnen">
          <template #prefix>
            <ul class="grid grid-cols-4 gap-4">
              <li v-for="(item, index) in statistieken" :key="`${index}-${item.client_id}`" class="card border-t-4 border-dashboard-dark">
                <div class="text-white px-1 rounded" :class="colorClass(item.gemiddelde)">
                  <strong>Gemiddelde: </strong> {{ item.gemiddelde }} dagen
                </div>
                <hr class="mt-2 mb-1">
                <FacturatieLijn
                  :hidden-btw="compact"
                  :label="null"
                  column
                  class="font-bold gray py-0"
                  :facturatie="item.financial_client"
                />
              </li>
            </ul>
          </template>
        </BamTarget>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

import BamTarget from '@/components/Charts/BamTarget.vue'
import FacturatieLijn from '@/components/Table/Planning/FacturatieLijn.vue'
import UIButtonCompact from '@/components/UI/Button/Compact.vue'
import UILoading from '@/components/UI/Loading.vue'

import useGetApi from '@/hooks/useGetApi.js'
import useRenderKey from '@/hooks/useRenderKey.js'

const props = defineProps({
  filters: Object,
  renderKey: [String, Number],
})

const compact = ref(false)

useRenderKey(props.renderKey)

const apiData = computed(() => Object.assign({}, props.filters))
const { data, loading } = useGetApi('STATS_BEDRIJVEN_BETAALTERMIJNEN', apiData, { watch: true })
const statistieken = computed(() => data.value?.statistieken || [])

const colorClass = gemiddelde => {
  if (gemiddelde < 30) return 'bg-green-500'
  if (gemiddelde < 60) return 'bg-yellow-500'
  if (gemiddelde < 90) return 'bg-orange-500'
  return 'bg-red-500'
}
</script>
